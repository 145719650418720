html, body {
    height: 100%;
}

body {
    background-color: rgb(214, 243, 253);
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.main-area {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}

#number-picker {
    background-color: white;
}

.side-bar {
    flex-grow: 0;
    padding: 10px;
    min-width: 400px;
    background-color: rgb(247, 243, 243);
    border-left: 3px groove rgb(241, 238, 238);

    display: flex;
    flex-direction: column;

    overflow-y: auto;
}

#theme_overrides {
    display: block;
    width: 375px;
    height: 500px;
    margin: 0 auto;
}

button#render {
    font-size: large;
    padding: 10px 0;
    margin: 5px 20px;
}

#events {
    min-height: 200px;
}

#unmount {
    padding: 10px 0;
    margin: 5px 20px;
}
